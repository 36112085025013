.Current-Edit {
  margin-top: 30px;
}

.colorbox {
  width: 15%;
  padding-top: 15%;
  cursor: pointer;
}

.namebox {
  width: 85%;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}

.name {
  position: relative;
  top: 50%;
  left: 8px;
  font-size: 12px;
  transform: translateY(-50%);
  font-weight: bold;
  border: 0px;
}

.edit {
  align-self: center;
  padding-right: 10px;
}

div.addfamily div.namebox div.name {
  position: relative;
  color: #b0bcc9;
  top: 50%;
  left: 8px;
  font-size: 12px;
  transform: translateY(-50%);
  font-weight: bold;
  align-self: flex-start;
}

.addfamily {
  cursor: pointer;
}

.family {
  border: 1px solid #f1f5f9;
  margin-bottom: 15px;
}

.currentColor0 {
  background: #f9bca0;
}

.currentColor1 {
  background: #dda7d7;
}

.currentColor2 {
  background: #c5dbef;
}

.currentColor3 {
  background: #c2ccff;
}

.currentColor4 {
  background: #9fd1a7;
}

.currentColor5 {
  background: #f4c2bd;
}

.currentColor6 {
  background: #f4b0f2;
}
